import locale4cb3dfaf from '../../../lang/en-highberg.js'
import locale6079e944 from '../../../lang/nl-highberg.js'
import locale083adb87 from '../../../lang/de-highberg.js'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"dateTimeFormats":{"nl":{"date":{"month":"long","day":"numeric"},"dateFull":{"month":"long","day":"numeric","year":"numeric"},"dateShort":{"month":"short","day":"numeric","year":"numeric"},"dateShorter":{"month":"short","day":"numeric"},"dateTime":{"month":"long","day":"numeric","hour":"numeric","minute":"numeric","hour12":false},"time":{"hour":"numeric","minute":"numeric","hour12":false,"timeZone":"Europe/Amsterdam"}},"en":{"date":{"month":"long","day":"numeric"},"dateFull":{"month":"long","day":"numeric","year":"numeric"},"dateShort":{"month":"short","day":"numeric","year":"numeric"},"dateShorter":{"month":"short","day":"numeric"},"dateTime":{"month":"long","day":"numeric","hour":"numeric","minute":"numeric","hour12":false},"time":{"hour":"numeric","minute":"numeric","hour12":false}},"fr":{"date":{"month":"long","day":"numeric"},"dateFull":{"month":"long","day":"numeric","year":"numeric"},"dateShort":{"month":"short","day":"numeric","year":"numeric"},"dateShorter":{"month":"short","day":"numeric"},"dateTime":{"month":"long","day":"numeric","hour":"numeric","minute":"numeric","hour12":false},"time":{"hour":"numeric","minute":"numeric","hour12":false}},"de":{"date":{"month":"long","day":"numeric"},"dateFull":{"month":"long","day":"numeric","year":"numeric"},"dateShort":{"month":"short","day":"numeric","year":"numeric"},"dateShorter":{"month":"short","day":"numeric"},"dateTime":{"month":"long","day":"numeric","hour":"numeric","minute":"numeric","hour12":false},"time":{"hour":"numeric","minute":"numeric","hour12":false}}}},
  vueI18nLoader: false,
  locales: [{"code":"en-Highberg","craftSiteHandle":"highbergEN","iso":"en","craftLanguage":"en-GB","file":"en-highberg.js","group":"highberg","domain":"https://www.highbergacademy.com","formatted":"English","countryCode":"GB"},{"code":"nl-Highberg","craftSiteHandle":"highbergNL","iso":"nl","file":"nl-highberg.js","group":"highberg","domain":"https://www.highbergacademy.nl","formatted":"Nederlands","countryCode":"NL"},{"code":"de-Highberg","craftSiteHandle":"highbergDE","iso":"de","file":"de-highberg.js","group":"highberg","domain":"https://www.highbergacademy.de","formatted":"Deutsch","countryCode":"DE"}],
  defaultLocale: "nl-Highberg",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_except_default",
  lazy: false,
  langDir: "lang/",
  rootRedirect: null,
  detectBrowserLanguage: false,
  differentDomains: true,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"code":"en-Highberg","craftSiteHandle":"highbergEN","iso":"en","craftLanguage":"en-GB","file":"en-highberg.js","group":"highberg","domain":"https://www.highbergacademy.com","formatted":"English","countryCode":"GB"},{"code":"nl-Highberg","craftSiteHandle":"highbergNL","iso":"nl","file":"nl-highberg.js","group":"highberg","domain":"https://www.highbergacademy.nl","formatted":"Nederlands","countryCode":"NL"},{"code":"de-Highberg","craftSiteHandle":"highbergDE","iso":"de","file":"de-highberg.js","group":"highberg","domain":"https://www.highbergacademy.de","formatted":"Deutsch","countryCode":"DE"}],
  localeCodes: ["en-Highberg","nl-Highberg","de-Highberg"],
  additionalMessages: [],
}
export const localeFiles = {
  0: "{",
  1: "\"",
  2: "e",
  3: "n",
  4: "-",
  5: "h",
  6: "i",
  7: "g",
  8: "h",
  9: "b",
  10: "e",
  11: "r",
  12: "g",
  13: ".",
  14: "j",
  15: "s",
  16: "\"",
  17: ":",
  18: "\"",
  19: ".",
  20: ".",
  21: "/",
  22: ".",
  23: ".",
  24: "/",
  25: "l",
  26: "a",
  27: "n",
  28: "g",
  29: "/",
  30: "e",
  31: "n",
  32: "-",
  33: "h",
  34: "i",
  35: "g",
  36: "h",
  37: "b",
  38: "e",
  39: "r",
  40: "g",
  41: ".",
  42: "j",
  43: "s",
  44: "\"",
  45: ",",
  46: "\"",
  47: "n",
  48: "l",
  49: "-",
  50: "h",
  51: "i",
  52: "g",
  53: "h",
  54: "b",
  55: "e",
  56: "r",
  57: "g",
  58: ".",
  59: "j",
  60: "s",
  61: "\"",
  62: ":",
  63: "\"",
  64: ".",
  65: ".",
  66: "/",
  67: ".",
  68: ".",
  69: "/",
  70: "l",
  71: "a",
  72: "n",
  73: "g",
  74: "/",
  75: "n",
  76: "l",
  77: "-",
  78: "h",
  79: "i",
  80: "g",
  81: "h",
  82: "b",
  83: "e",
  84: "r",
  85: "g",
  86: ".",
  87: "j",
  88: "s",
  89: "\"",
  90: ",",
  91: "\"",
  92: "d",
  93: "e",
  94: "-",
  95: "h",
  96: "i",
  97: "g",
  98: "h",
  99: "b",
  100: "e",
  101: "r",
  102: "g",
  103: ".",
  104: "j",
  105: "s",
  106: "\"",
  107: ":",
  108: "\"",
  109: ".",
  110: ".",
  111: "/",
  112: ".",
  113: ".",
  114: "/",
  115: "l",
  116: "a",
  117: "n",
  118: "g",
  119: "/",
  120: "d",
  121: "e",
  122: "-",
  123: "h",
  124: "i",
  125: "g",
  126: "h",
  127: "b",
  128: "e",
  129: "r",
  130: "g",
  131: ".",
  132: "j",
  133: "s",
  134: "\"",
  135: "}",
}

export const localeMessages = {
  'en-highberg.js': () => Promise.resolve(locale4cb3dfaf),
  'nl-highberg.js': () => Promise.resolve(locale6079e944),
  'de-highberg.js': () => Promise.resolve(locale083adb87),
}
